import * as React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

import logoEN from "../images/logo_orange_en.svg";
import logoUA from "../images/logo_orange_ua.svg";
import texts from "./texts";

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2em;
`;

const Logo = styled.img`
  width: 25vw;
  min-width: 140px;
  max-width: 220px;
`;

const LanguageSwitcher = styled.div`
  & > * {
    transition: text-shadow 0.1s ease-in;
  }

  a,
  a:hover,
  a:visited,
  & > * {
    color: #032727;
    text-decoration: none;
    font-weight: 500;
  }

  a,
  a:visited {
    padding: 0.5em;
    text-shadow: rgba(255, 255, 255, 0.75) 0px 0px 0em;
  }

  a:hover {
    text-shadow: rgba(255, 255, 255, 0.75) 0px 1px 0.2em;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 1em;
  flex-direction: row;
  justify-content: flex-start;

  font-size: 0.8em;
  color: #fff !important;

  opacity: 0.75;
  transition: opacity 0.2s ease-in;

  &:hover,
  & > *:hover {
    opacity: 1;
  }

  & > * {
    margin-left: 1em;
  }

  a,
  a:hover,
  a:visited {
    color: #fff;
    text-decoration: none;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1em;

  @media only screen and (max-width: 480px) {
    font-size: 0.5em;
  }
  @media only screen and (max-width: 800px) {
    font-size: 0.66em;
  }
  @media only screen and (max-width: 1200px) {
    font-size: 0.85em;
  }
`;

const Layout = (props) => {
  const { lang, hideFooter } = props;

  const localizedLink = (page = "") => `/${lang}/${page}`;
  const date = new Date();

  return (
    <LayoutWrapper>
      <Helmet>
        <html lang={lang} />
      </Helmet>
      <Header>
        <Link to={localizedLink()}>
          <Logo
            src={lang === "en" ? logoEN : logoUA}
            alt={texts[lang].logoAlt}
          />
        </Link>
        <LanguageSwitcher>
          <Link to="/en">EN</Link>
          <span>|</span>
          <Link to="/ua">UA</Link>
        </LanguageSwitcher>
      </Header>
      <ContentWrapper>{props.children}</ContentWrapper>
      {!hideFooter && (
        <Footer>
          © {texts[lang].fzs} {date.getFullYear()}
          <span>|</span>
          <Link to={localizedLink("imprint")}>{texts[lang].imprint}</Link>
          <span>|</span>
          <Link to={localizedLink("privacy")}>{texts[lang].privacyPolicy}</Link>
        </Footer>
      )}
    </LayoutWrapper>
  );
};

export default Layout;
