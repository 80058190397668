import * as React from "react";
import { Link } from "gatsby";

const texts = {
  en: {
    logoAlt: "Carpathian Primary Forest Conservation",
    heading: "Coming soon: a knowledge hub for the Carpathian Forests.",
    callToAction:
      "Sign-up for news and insights from the Ukrainian Carpathians:",
    signUp: "Sign up",
    email: "Email",
    privacyPolicyAccepted: (
      <span>
        I understand the <Link to="/en/privacy">privacy policy</Link>, agree to
        receive this newsletter and know that I can easily unsubscribe at any
        time.
      </span>
    ),
    imprint: "Imprint",
    privacyPolicy: "Privacy Policy",
    fzs: "Frankfurt Zoological Society",
    english: "English",
    ukrainian: "Ukrainian",
    successPage: {
      heading: "Thank you for signing up for our newsletter",
      paragraph:
        "By signing up to the Carpathian Primary Forest Conservation programme’s newsletter you will receive quarterly news and insights on conservation efforts in the vast primary forests of the Ukrainian Carpathians. We work in collaboration with partners in Ukraine to expand and strengthen protected areas, monitor biodiversity, improve the livelihoods of communities living around parks and raise awareness on the importance of nature and wildlife.",
    },
    toasts: {
      noEmailAddress: "Enter an email address, please.",
      noPrivacyPolicyAccepted: "Accept the privacy policy, please.",
      reCaptchaInvalid:
        "ReCaptcha verification failed. Please try again or reload your page.",
      success:
        "Your data was submitted. Please verify your subscription from your inbox.",
      errorTryAgain: "Error during submission of your data. Please try again.",
    },
  },
  ua: {
    logoAlt: "Збереження Карпатських пралісів",
    heading: "Вже скоро: осередок знань про карпатські ліси",
    callToAction: "Підпишіться, щоб отримувати новини з українських Карпат",
    email: "Email",
    signUp: "Підписатися",
    privacyPolicyAccepted: (
      <span>
        Я розумію <Link to="/ua/privacy">політику конфіденційності</Link>,
        погоджуюсь отримувати цю розсилку та знаю, що я можу відписатись у
        будь-який час.
      </span>
    ),
    imprint: "Імпресум",
    privacyPolicy: "Політика конфіденційності",
    fzs: "Франкфуртське зоологічне товариство",
    english: "Англійська",
    ukrainian: "Українська",
    successPage: {
      heading: "Дякуємо за те, що підписались на нашу розсилку",
      paragraph:
        "Підписавшись на інформаційний бюлетень програми “Збереження Карпатських пралісів”, ви будете отримувати щоквартальні новини та інформацію про зусилля щодо збереження природних лісів Українських Карпат. Ми співпрацюємо з партнерами в Україні з метою розширення та зміцнення заповідних територій, моніторингу біорізноманіття, покращення добробуту місцевих громад, та підвищення обізнаності про важливість дикої природи.",
    },
    toasts: {
      noEmailAddress: "Введіть свій емейл, будь ласка.",
      noPrivacyPolicyAccepted:
        "Прийміть політику конфіденційності, будь ласка.",
      reCaptchaInvalid:
        "Помилка перевірки ReCaptcha. Спробуйте ще раз або перезавантажте сторінку.",
      success:
        'Ваші дані були надіслані. Будь ласка, підтвердьте свою підписку з папки "Вхідні".',
      errorTryAgain:
        "Помилка під час надсилання ваших даних. Будь ласка спробуйте ще раз.",
    },
  },
};

const imprintPageTexts = {
  en: (
    <div>
      <h2>Imprint</h2>
      <ul>
        <li>
          <b>Frankfurt Zoological Society</b>
        </li>
        <li>Bernhard-Grzimek-Allee 1</li>
        <li>D-60316 Frankfurt / M.</li>
        <li>Germany</li>
        <li>
          Phone: <a href="tel:0049699434460">+49 (0)69 94 34 46 0</a>
        </li>
        <li>Fax: +49 (0)69 439 348</li>
        <li>
          Email: <a href="mailto:info@fzs.org">info@fzs.org</a>
        </li>
        <li>
          Internet:{" "}
          <a href="https://fzs.org" target="_blank" rel="noopener noreferrer">
            www.fzs.org
          </a>
        </li>
      </ul>

      <ul>
        <li>
          <b>President:</b> Klaus Becker
        </li>
        <li>
          <b>Vice President:</b> Professor Dr. Manfred Niekisch
        </li>
        <li>
          <b>Register Court:</b> Amtsgericht Frankfurt
        </li>
        <li>
          <b>Register Number:</b> VR 5228
        </li>
      </ul>

      <ul>
        <li>
          <b>Editor in Charge:</b>
        </li>
        <li>Michael Brombacher, Head of Europe Department, FZS</li>
      </ul>

      <ul>
        <li>
          <b>Data protection officer:</b>
        </li>
        <li>
          External data protection officer from TÜV Hessen
          <br />
          You have questions about your data? Please contact us at{" "}
          <a href="mailto:datenschutz@zgf.de">datenschutz@zgf.de</a>
        </li>
      </ul>

      <ul>
        <li>
          <b>Photos by:</b>
        </li>
        <li>
          <a
            href="https://danielrosengren.se/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Daniel Rosengren
          </a>
        </li>
      </ul>

      <ul>
        <li>
          <b>Design & Programming:</b>
        </li>
        <li>
          <a
            href="https://jschroeer.de"
            target="_blank"
            rel="noopener noreferrer"
          >
            Jeldrik Schröer
          </a>
        </li>
      </ul>

      <ul>
        <li>© FZS, Frankfurt am Main, {new Date().getFullYear()}</li>
      </ul>

      <ul>
        <li>
          <b>Copyright Notice</b>
        </li>
        <li>
          All parts of this website (textual information, images etc.) are
          copyrighted. They must not be reproduced without prior permission by
          Frankfurt Zoological Society.
        </li>
      </ul>
    </div>
  ),
  ua: (
    <div>
      <h2>Імпресум</h2>
      <ul>
        <li>
          <b>Франкфуртське зоологічне товариство</b>
        </li>
        <li>Алея Бернарда Гржимека, 1</li>
        <li>D-60316 Франкфурт-на-Майні</li>
        <li>Німеччина</li>
        <li>
          Телефон: <a href="tel:0049699434460">+49 (0)69 94 34 46 0</a>
        </li>
        <li>Факс: +49 (0)69 439 348</li>
        <li>
          Ел.пошта: <a href="mailto:info@fzs.org">info@fzs.org</a>
        </li>
        <li>
          Веб-сайт:{" "}
          <a href="https://fzs.org" target="_blank" rel="noopener noreferrer">
            www.fzs.org
          </a>
        </li>
      </ul>

      <ul>
        <li>
          <b>Президент:</b> Клаус Бекер
        </li>
        <li>
          <b>Віце-президент:</b> Професор д-р Манфред Нікіш
        </li>
        <li>
          <b>Суд, що веде реєстр:</b> Окружний суд Франкфурта
        </li>
        <li>
          <b>Реєстраційний номер:</b> VR 5228
        </li>
      </ul>

      <ul>
        <li>
          <b>Головний редактор:</b>
        </li>
        <li>Міхаель Бромбахер, Головний відділ в Європі, ФЗТ</li>
      </ul>

      <ul>
        <li>
          <b>Особа, відповідальна за захист даних:</b>
        </li>
        <li>
          Стороння особа, відповідальна за захист даних від «ТЮФ Гессен»
          <br />У Вас є питання, пов’язані з Вашими даними? Зв’яжіться з нами за
          адресою електронної пошти{" "}
          <a href="mailto:datenschutz@zgf.de">datenschutz@zgf.de</a>
        </li>
      </ul>

      <ul>
        <li>
          <b>Фотографії:</b>
        </li>
        <li>
          <a
            href="https://danielrosengren.se/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Даніель Розенгрен
          </a>
        </li>
      </ul>

      <ul>
        <li>
          <b>Дизайн і програмування:</b>
        </li>
        <li>
          <a
            href="https://jschroeer.de"
            target="_blank"
            rel="noopener noreferrer"
          >
            Єльдрік Шреер
          </a>
        </li>
      </ul>

      <ul>
        <li>© ФЗТ, Франкфурт-на-Майні, {new Date().getFullYear()} рік</li>
      </ul>

      <ul>
        <li>
          <b>Відмітка про авторське право</b>
        </li>
        <li>
          Всі компоненти цього інтернет-сайту (текстова інформація, зображення
          та ін.) Захищені авторськими правами. Забороняється їх відтворення без
          отримання попереднього дозволу від Франкфуртського зоологічного
          товариства.
        </li>
      </ul>
    </div>
  ),
};

const privacyPolicyPageTexts = {
  en: (
    <div>
      <section>
        <h1>Privacy Policy</h1>
        <p style={{ textAlign: "center" }}>
          <b>
            The protection of the data of our supporters as well as the visitors
            and users of our website is particularly important to us.
          </b>
        </p>
      </section>

      <h2>Collection and processing of personal data</h2>

      <section>
        Usually, you can visit the pages of the Frankfurt Zoological Society
        without exposing any personal data. Personal data is only collected if
        you provide it to us voluntarily.
      </section>

      <h2>Use and disclosure of personal data</h2>

      <section>
        All personal data collected in the context of the direct Internet
        services of the Frankfurt Zoological Society will be collected,
        processed and used in accordance with the applicable regulations for the
        protection of personal data, only for the improvement and availability
        of our services and for safeguarding our own legitimate interests with
        regard to the support and information of our sponsors and interested
        parties.
      </section>

      <h2>Data transfer outside the European Economic Area</h2>

      <section>
        At present a transfer to countries outside the European economic area is
        neither carried out nor planned, otherwise the corresponding legal
        requirements will be created. In particular, you will be informed about
        the respective recipients or categories of recipients in accordance with
        legal requirements.
      </section>

      <h2>Security</h2>

      <section>
        We use technical and organizational security measures to protect your
        data from manipulation, loss, destruction or access by unauthorized
        persons. Our security measures are continuously improved in line with
        technological developments.
      </section>

      <h2>Cookies</h2>

      <section>
        We make use of cookies on our website. A cookie is a small file that is
        stored on your computer when you visit a website. If you visit the
        website again, the cookie indicates that it is a repeated visit. The
        cookie contains a unique number and, if applicable, a date and no
        personal information. The cookie is not suitable to identify you on the
        websites of third parties, including the websites of the analytics
        providers.
        <br />
        <br />
        You can configure your browser to refuse the acceptance of cookies in
        principle or to inform you in advance if a cookie is stored. Please
        refer to your Internet browser's instruction manual for more
        information.
        <br />
        We do not use IP addresses or cookies to personally identify you. We use
        the systems with the aim of increasing the efficiency of our website and
        our marketing.
      </section>

      <h2>Visit to our website</h2>

      <section>
        We collect and store the IP address assigned to your computer. It is
        also stored which pages of our website visitors visit in each case. The
        date, time and duration of the visit and the browser a visitor uses to
        access the Internet are also stored. This data is evaluated for
        statistical purposes and to ensure the proper functioning of our
        website; the respective users are not traced.
      </section>

      <h2>Google reCaptcha</h2>

      <section>
        We use the Google service reCaptcha to determine whether a person or
        computer is making a particular entry in our contact or newsletter form.
        Google uses the following information to determine whether you are a
        human being or a computerbot: IP address of the end device used, the
        website you visit and where the captcha is integrated, the date and
        duration of the visit, the type of browser and operating system used,
        Google account if you are logged in at Google, mouse movements on the
        reCaptcha areas as well as tasks in which you have to identify images.
        The legal basis for the described data processing is Art. 6 para. 1 lit.
        f of the Basic Data Protection Regulation. There is a legitimate
        interest on our part in this data processing to ensure the security of
        our website and to protect us from automated entries (attacks).
      </section>

      <h2>Google Web Fonts</h2>

      <section>
        We use web fonts provided by Google for a uniform representation of
        fonts. When you open a page, the required web fonts are loaded into your
        browser cache to display texts and fonts correctly. For this purpose
        your browser has to establish a direct connection to Google servers.
        Google thus becomes aware that our website was accessed via your IP
        address. The use of Google Web fonts is done in the interest of a
        uniform and attractive presentation of our website, so it is pursuant to
        Art. 6 (1) (f) DSGVO. Further information can be found at&nbsp;
        <a
          href="https://developers.google.com/fonts/faq"
          target="_blank"
          rel="noopener noreferrer"
        >
          developers.google.com/fonts/faq
        </a>{" "}
        and in Google’s privacy policy at&nbsp;
        <a
          href="https://www.google.com/policies/privacy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          google.com/policies/privacy/
        </a>
        .
      </section>

      <h2>Links to websites of other providers </h2>

      <section>
        Our website may contain links to websites of other providers. We would
        like to point out that this privacy policy applies exclusively to the
        websites of the Frankfurt Zoological Society. We have no influence on
        and do not control that other providers comply with the applicable data
        protection regulations.
      </section>

      <h2>Standard periods for data deletion</h2>

      <section>
        The legislator has enacted various retention periods and obligations.
        After expiry of these periods, the corresponding data is routinely
        deleted. If data is not affected by this, it will be deleted or made
        anonymous when the purposes stated in this privacy policy cease to
        apply. If this privacy policy does not contain any other, deviating
        provisions regarding the storage of data, the data collected by us will
        be stored by us for as long as they are necessary for the aforementioned
        purposes for which they were collected.
      </section>

      <h2>Other data uses and data deletion</h2>

      <section>
        Further processing or use of your personal data will generally only take
        place if permitted by law or if you have consented to data processing or
        use. In the event of further processing for purposes other than those
        for which the data were originally collected, we will inform you of
        these other purposes prior to further processing and provide you with
        further relevant information.
      </section>

      <h2>Your rights</h2>

      <section>
        You have the right to request a copy of your information, to ask us to
        correct information that is wrong, to delete it or to request that we
        only use it for certain purposes. You can also ask us to stop using your
        information, for example to unsubscribe from any mailings about our work
        and projects.
        <br />
        <br />
        For reasons arising from your particular situation, you have the right
        to object at any time to the processing of personal data concerning you,
        which is carried out, on the basis of Art. 6 para. 1 lit. e or f DSGVO,
        in accordance with Art. 21 DSGVO. We will stop processing your personal
        data unless we can prove compelling reasons for processing that outweigh
        your interests, rights and freedoms, or the processing serves to assert,
        exercise or defend legal claims. To exercise your above right, please
        contact the addresses given below.
      </section>

      <h2>Data Protection Officer of the Frankfurt Zoological Society</h2>

      <section>
        TÜV Technische Überwachung Hessen GmbH
        <br />
        <a href="mailto:datenschutz@zgf.de">datenschutz@zgf.de</a>
        <br />
        <br />
        Contact details of the person responsible:
        <br />
        Zoologische Gesellschaft Frankfurt von 1858 e.V.
        <br />
        Bernhard-Grzimek-Allee 1<br />
        60316 Frankfurt, Germany
        <br />
        Phone: +49 (0)69 - 94 34 46 0<br />
        Fax: +49 (0)69 - 43 93 48
      </section>

      <h2>Validity of the privacy policy</h2>

      <section>
        By using our website, you consent to the use of data as described above.
        This privacy policy is currently valid and has the status as of July
        2019.
        <br />
        <br />
        Due to the further development of our website or the implementation of
        new technologies, it may become necessary to change this data protection
        declaration. We reserve the right to change the data protection
        declaration at any time with effect for the future. We recommend that
        you re-read the current privacy policy from time to time.
      </section>
    </div>
  ),
  ua: (
    <div>
      <section>
        <h1>Політика конфіденційності</h1>
        <p style={{ textAlign: "center" }}>
          <b>
            Захист даних наших спонсорів, так само як і відвідувачів і
            користувачів нашого сайту, представляє для нас особливу важливість.
          </b>
        </p>
      </section>

      <h2>Збір та обробка персональних даних</h2>

      <section>
        Як правило, Ви можете відвідувати сторінки Франкфуртського зоологічного
        товариства без розкриття будь-яких персональних даних. Персональні дані
        збираються тільки в тому випадку, якщо Ви надаєте їх нам добровільно,
        наприклад, при заповненні бланка про внесення пожертвування.
      </section>

      <h2>Використання і розкриття персональних даних</h2>

      <section>
        Всі персональні дані, зібрані в рамках прямих інтернет-сервісів
        Франкфуртського зоологічного товариства, будуть збиратися, оброблятися і
        використовуватися відповідно до застосовних норм про захист персональних
        даних виключно для цілей обробки пожертвувань, участі або спонсорської
        підтримки, а також для захисту наших власних законних інтересів щодо
        підтримки та інформації про наших спонсорів і зацікавлені сторони.
      </section>

      <h2>Передача даних за межі Європейської економічної зони</h2>

      <section>
        На даний момент передача даних в країни, що не входять в Європейську
        економічну зону, не здійснюється і не планується до здійснення, в іншому
        випадку будуть розроблені відповідні правові вимоги. Зокрема, Вас
        повідомлять про відповідних одержувачів або категорії одержувачів
        відповідно до нормативних вимог.
      </section>

      <h2>Безпека</h2>

      <section>
        Ми вживаємо технічні та організаційні заходи безпеки для захисту Ваших
        даних від маніпуляцій, втрати, знищення або несанкціонованого доступу.
        Наші заходи безпеки постійно поліпшуються в міру розвитку технологій.
      </section>

      <h2>Cookies</h2>

      <section>
        Наш сайт використовує cookies. Cookie являє собою невеликий файл, який
        зберігається на Вашому комп’ютері при відвідуванні сайту. Якщо Ви
        відвідуєте сайт повторно, cookie вказує на повторний візит. Файл cookie
        містить унікальний номер і у певних випадках дату. Файл cookie не
        містить жодної персональної інформації. Cookie не придатний для Вашої
        ідентифікації на сайтах третіх осіб, в тому числі на сайтах
        постачальників аналітичних послуг.
        <br />
        <br /> Ви можете налаштувати Ваш браузер таким чином, щоб відмовлятися
        від прийняття cookies в цілому або щоб отримувати попереднє повідомлення
        про те, що використовується файл cookie. Для отримання більш докладної
        інформації ознайомтеся з інструкцією користувача для Вашого
        інтернет-браузера. Ми не використовуємо IP-адреси і cookies для
        ідентифікації Вашої особи. Ми використовуємо ці системи з метою
        підвищення ефективності нашого сайту і маркетингу.
      </section>

      <h2>Відвідування нашого сайту</h2>

      <section>
        Ми зчитуємо і зберігаємо IP-адресу, присвоєну Вашому комп’ютеру. Також
        зберігаються відомості про сторінки нашого сайту, які відвідуються в
        кожному окремому випадку. Крім цього, зберігаються дата, час, тривалість
        візиту, а також відомості про браузер, який використовується
        відвідувачем для доступу до мережі Інтернет. Ці дані оцінюються в
        статистичних цілях і для забезпечення нормальної роботи нашого сайту;
        вони не використовуються для відстеження користувачів.
      </section>

      <h2>Google reCaptcha</h2>

      <section>
        Ми використовуємо службу Google reCaptcha, щоб визначити, чи запис
        контакту або нашої форми розсилки здійснює людина чи комп'ютер. Google
        використовує таку інформацію, щоб визначити, чи є ви людиною чи
        комп'ютерним ботом: IP-адреса кінцевого використовуваного пристрою,
        веб-сайт, який ви відвідуєте, і де інтегрована капча, дата та тривалість
        візиту, тип браузера та операційна система, обліковий запис Google, якщо
        ви ввійшли в Google, рух миші в областях reCaptcha, а також завдання, в
        яких вам потрібно ідентифікувати зображення. Правовою основою для
        описаної обробки даних є ст. 6 абз. 1 пункт. f Загального регламенту
        захисту даних. З нашого боку інтерес у цій обробці даних є законним для
        забезпечення безпеки нашого веб-сайту та захисту від автоматизованих
        входів (атак).
      </section>

      <h2>Посилання на сайти інших провайдерів</h2>
      <section>
        На нашому сайті можуть бути присутніми посилання на сайти інших
        провайдерів. Ми хочемо підкреслити, що ця політика конфіденційності
        застосовується виключно до сайтів Франкфуртського зоологічного
        товариства. Ми не маємо жодного впливу на інших провайдерів і не
        контролюємо дотримання ними відповідних норм щодо захисту даних.
      </section>

      <h2>Стандартні терміни видалення даних</h2>

      <section>
        Законодавцем закріплено різні терміни і обов’язки по зберіганню даних.
        Після закінчення зазначених термінів відповідні дані видаляються в
        стандартному порядку. Якщо до даних не застосовується така дія, вони
        будуть видалені або знеособлені в момент, коли цілі, зазначені у цій
        політиці конфіденційності, перестануть застосовуватися. Якщо політика
        конфіденційності не містить жодних інших відмінних положень із
        зберігання даних, зібрані нами дані будуть зберігатися протягом періоду,
        необхідного для виконання вищевказаних цілей, для яких вони були
        зібрані.
      </section>

      <h2>Використання даних в інших цілях і видалення даних</h2>

      <section>
        Подальша обробка або використання Ваших персональних даних відповідно до
        загальних правил відбуватиметься тільки в рамках закону або якщо Ви дали
        згоду на обробку або використання даних. У разі подальшої обробки для
        цілей, відмінних від тих, для яких спочатку збиралися дані, ми
        повідомимо Вас про ці відмінні цілі до подальшої обробки і надамо Вам
        подальшу відповідну інформацію.
      </section>

      <h2>Ваші права</h2>

      <section>
        У Вас є право затребувати копію Ваших даних, запросити від нас виправити
        помилки в них, видалити їх або вимагати, щоб вони були використані нами
        лише для певних цілей. Ви також можете звернутися до нас із запитом
        припинити використання Ваших даних – наприклад, відписатися від розсилок
        про нашу роботу і наші проекти.
        <br />
        <br />З причин, які виникають з конкретної ситуації з Вами, Ви маєте
        право в будь-який час відмовитися від обробки Ваших персональних даних,
        яка здійснюється на підставі ст. 6 п. 1 літ. е Загального регламенту
        щодо захисту даних відповідно до ст. 21 Загального регламенту захисту
        даних. Ми припинимо обробку Ваших персональних даних, якщо не зможемо
        довести вагомі підстави для обробки, які мають більше значення, ніж Ваші
        інтереси, права і свободи, і якщо обробка не потрібна для пред’явлення,
        виконання або здійснення захисту за позовними вимогами. Для здійснення
        Вашого вищевказаного права зв’яжіться з нами за адресами, вказаними
        нижче.
      </section>

      <h2>
        Особа, відповідальна за захист даних Франкфуртського зоологічного
        товариства
      </h2>

      <section>
        TÜV Technische Überwachung Hessen GmbH
        <br />
        <a href="mailto:datenschutz@zgf.de">datenschutz@zgf.de</a>
        <br />
        <br />
        Контактні дані відповідальної особи:
        <br />
        «Франкфуртське зоологічне товариство»,
        <br />
        Некомерційна організація, заснована в 1858 р.
        <br />
        Алея Бернарда Гржимека, 1<br />
        60316 Франкфурт
        <br />
        Телефон: +49 (0)69 - 94 34 46 0<br />
        Факс: +49 (0)69 - 43 93 48
      </section>

      <h2>Чинність політики конфіденційності</h2>

      <section>
        Використовуючи наш сайт, Ви погоджуєтеся на використання зазначених вище
        даних. Чинна політика діє з травня 2018 року.
        <br />
        <br />У зв’язку з подальшим розвитком нашого сайту або впровадженням
        нових технологій може виникнути необхідність в зміні цієї заяви про
        захист даних. Ми зберігаємо за собою право в будь-який час внести в
        заяву про захист даних зміни, які будуть діяти в майбутньому. Ми
        рекомендуємо Вам періодично перечитувати положення актуальної політики
        конфіденційності.
      </section>
    </div>
  ),
};

export { imprintPageTexts, privacyPolicyPageTexts };
export default texts;
